<template>
  <v-app :style="{ backgroundColor: bgColor }">
    <router-view />
  </v-app>
</template>

<script>
export default {
  computed: {
    bgColor() {
      return this.$route.meta.backgroundColor || "white";
    },
  },
};
</script>
